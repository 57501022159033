import "./App.css";
import { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import useSound from "use-sound";
import PlayAudioToggle from "components/PlayAudioToggle";
import RandomPhrase from "components/RandomPhrase";

const getRandomSound = (): string => {
  const SOUNDS: string[] = [
    "sounds/notify1.ogg",
    "sounds/notify2.ogg",
    "sounds/notify3.ogg",
    "sounds/notify4.ogg",
    "sounds/notify5.ogg",
    "sounds/notify6.ogg",
    "sounds/notify7.ogg",
    "sounds/notify8.ogg",
  ];
  return SOUNDS[Math.floor(Math.random() * SOUNDS.length)];
};

export default function App(): JSX.Element {
  const [leftPopoverIsOpen, setLeftPopoverIsOpen] = useState<boolean>(false);
  const [rightPopoverIsOpen, setRightPopoverIsOpen] = useState<boolean>(false);
  const [codeAnimating, setCodeAnimating] = useState<boolean>(false);
  const [trashcanUp, setTrashcanUp] = useState<boolean>(true);
  const [playAudio, setPlayAudio] = useState<boolean>(true);
  const [_playRandomSound, { stop: stopRandomSound }] = useSound(
    getRandomSound(),
    {
      volume: 0.2,
    }
  );
  const [_playMouseSound, { stop: stopMouseSound }] = useSound(
    "sounds/mouseClick.mp3"
  );
  const [_playKeyboardSound, { stop: stopKeyboardSound }] = useSound(
    "sounds/keyboardTyping.mp3",
    {
      onend: () => {
        setCodeAnimating(false);
      },
    }
  );
  const [_playTrashcanSound, { stop: stopTrashcanSound }] = useSound(
    "sounds/paperToss.mp3"
  );
  const [_playMusic, { stop: stopMusic }] = useSound("sounds/music.mp3", {
    volume: 0.2,
  });

  const playRandomSound = () => {
    stopRandomSound();
    playAudio && _playRandomSound();
  };
  const playMouseSound = () => {
    stopMouseSound();
    stopKeyboardSound();
    playAudio && _playMouseSound();
  };
  const playKeyboardSound = () => {
    stopMouseSound();
    stopKeyboardSound();
    playAudio && _playKeyboardSound();
  };
  const playTrashcanSound = () => {
    stopTrashcanSound();
    playAudio && _playTrashcanSound();
  };
  const playMusic = () => {
    stopMusic();
    playAudio && _playMusic();
  };

  /**
   * Close all popover elements
   */
  const closeAllPopovers = useCallback(() => {
    setLeftPopoverIsOpen(false);
    setRightPopoverIsOpen(false);
  }, []);

  /**
   * Stop all audio
   */
  const stopSound = useCallback(() => {
    stopRandomSound();
    stopMouseSound();
    stopKeyboardSound();
    stopTrashcanSound();
    stopMusic();
  }, [
    stopRandomSound,
    stopMouseSound,
    stopKeyboardSound,
    stopTrashcanSound,
    stopMusic,
  ]);

  /**
   * Toggle left popover when left monitor is clicked
   */
  const handleClickLeftMonitor = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    closeAllPopovers();
    !leftPopoverIsOpen && playRandomSound();
    setLeftPopoverIsOpen(!leftPopoverIsOpen);
  };

  /**
   * Toggle right popover when right monitor is clicked
   */
  const handleClickRightMonitor = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    closeAllPopovers();
    !rightPopoverIsOpen && playRandomSound();
    setRightPopoverIsOpen(!rightPopoverIsOpen);
  };

  /**
   * Play sound and animate monitor when keyboard clicked
   */
  const handleClickKeyboard = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    closeAllPopovers();
    playKeyboardSound();
    setCodeAnimating(true);
  };
  const getCodeStyles = (): React.CSSProperties => {
    if (codeAnimating) {
      return {
        backgroundImage: 'url("/images/layer_codeAnimating.gif")',
      };
    }
    return {
      backgroundImage: 'url("/images/layer_codeStatic.png")',
    };
  };

  /**
   * Play sound when mouse clicked
   */
  const handleClickMouse = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    closeAllPopovers();
    playMouseSound();
  };

  /**
   * Play music when headphones clicked
   */
  const handleClickHeadphones = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    closeAllPopovers();
    playMusic();
  };

  /*
   * Play sound when trashcan is knocked down
   */
  const handleClickTrashcan = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    closeAllPopovers();
    trashcanUp && playTrashcanSound();
    setTrashcanUp(!trashcanUp);
  };
  const getTrashcanStyles = (): React.CSSProperties => {
    if (trashcanUp) {
      return {
        backgroundImage: 'url("/images/layer_canUp.png")',
      };
    }
    return {
      backgroundImage: 'url("/images/layer_canOver.png")',
    };
  };

  /**
   * Bind a document click (outside of a button) to close all popovers and stop audio
   */
  useEffect(() => {
    const handleClickOutside = () => {
      closeAllPopovers();
      setCodeAnimating(false);
      stopSound();
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [stopSound, closeAllPopovers]);

  /**
   * Bind the escape key to close all popovers and stop audio
   */
  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeAllPopovers();
        setCodeAnimating(false);
        stopSound();
      }
    };
    window.addEventListener("keydown", handleKeypress);
    return () => {
      window.removeEventListener("keydown", handleKeypress);
    };
  }, [stopSound, closeAllPopovers]);

  /**
   * Print a message in console one time...
   */
  useEffect(() => {
    console.info("CAUGHT YOU LOOKING.....\n\nGet out of here, Perla! 😈");
  }, []);

  return (
    <div className="app">
      <div className="bg-image">
        <div className="bg-image" style={getTrashcanStyles()}>
          <div className="bg-image" style={getCodeStyles()}>
            <button
              type="button"
              onClick={handleClickLeftMonitor}
              className="monitor-left"
            />
            <button
              type="button"
              onClick={handleClickRightMonitor}
              className="monitor-right"
            />
            <button
              type="button"
              onClick={handleClickKeyboard}
              className="keyboard"
            />
            <button
              type="button"
              onClick={handleClickMouse}
              className="mouse"
            />
            <button
              type="button"
              onClick={handleClickHeadphones}
              className="headphones"
            />
            <button
              type="button"
              onClick={handleClickTrashcan}
              className={classNames("trashcan", { over: !trashcanUp })}
            />

            {leftPopoverIsOpen && (
              <div className="popover popover-left pixel-corners">
                <RandomPhrase />
              </div>
            )}

            {rightPopoverIsOpen && (
              <div className="popover popover-right pixel-corners">
                <RandomPhrase />
              </div>
            )}
          </div>
          <PlayAudioToggle playAudio={playAudio} setPlayAudio={setPlayAudio} />
        </div>
      </div>
    </div>
  );
}
