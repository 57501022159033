import { useEffect } from "react";
let displayPhrase = "";
const getRandomPhrase = (): string => {
  const PHRASES: string[] = [
    "You are genuinely such a blessing in my life. I have never had a friend that meant this much to me, and I remain so grateful to know you.",
    "I am in awe of your skills and your drive to learn. I love watching you pick up new things and become proficient by dedicating yourself and putting in the work. ",
    "You are one of the most beautiful people I've ever met, inside and out. ",
    "Watching the way you love and care for Bijoux makes my heart happy. You are such a good dog mom.",
    "I love the way you communicate. You are so good at saying what you mean without being rude or brash. You are always considerate.",
    "Your makeup skills are unbelievable and I am forever jealous. ",
    "If you ever feel like someone is talking about you behind your back, please know that it's probably me bragging about how amazing you are to someone who has never even met you.",
    "I am inspired by your dedication. When you decide you want to do something, you are going to do it. Nothing can stop you. It's incredible.",
    "Being around you is like being at peace. It is difficult to be stressed or anxious when we spend time together because you are a calming presence.",
    "You get me in a way that very few other people ever have, and I have difficulty expressing how much that means to me. Thank you.",
    "I will never get over how fantastic your hair is. It doesn't matter what you do with it, I always think it looks amazing.",
    "Your sense of style is completely adorable. Every style you try works and I love getting to see your outfits for pole on Instagram.",
    "You are the kind of person that everyone wants to be friends with and I think that says so much about who you are and how you treat others. ",
    "You deserve every good thing. I know that sometimes you struggle with that, so I will say it again: you deserve EVERY good thing.",
    "In the whole world, there is only one Perla Giselle Oki, and she is my best friend.",
    "You are so good at making me feel validated. The way you treat me and speak to me, and the way you listen to me, everything you do makes me feel seen and heard.",
    "You have such a creative spirit and I love getting to see the fruits of your labor, from cooking to sewing to home decor. You are my favorite artist.",
    "I love the way you laugh when something really gets you going and you can barely breathe through the giggles. It's contagious and beautiful.",
    "I value your insights and opinions and advice more than I can say. You are the person I turn to when I know I need honesty.",
    "Having sleepovers at your house while watching anime and eating Chinese food and cuddling in bed after are core memories for me and I will cherish them forever.",
    "I love sending you silly little reels and watching the ones you send me in return. It always brightens my day.",
    "I want to be there for all of your pole graduations. You are so incredibly talented and strong and I love being able to cheer you on from the sidelines.",
    "You literally make the world a better place. Your presence has a positive impact on almost everyone you interact with.",
    "Your friendship enriches my life in so many ways and I will spend the rest of my life trying to make sure I can do the same for you.",
    "I ❤️ your boobs.",
    "I love you the most.",
    "You are more than a friend: you are my chosen family.",
    "You help me to be the best version of myself that I can be, and you push me to make tough choices when I need to.",
    "I am grateful that you have always been there for me when I needed you, and I always want to be there for you when you need me.",
    "Your strength and resilience inspires me every day. I always admire your ability to work through setbacks and challenges.",
    "I'm so proud of your accomplishments and who you are. You are incredible.",
    "Your dreams and goals are within reach; keep pushing forward. I will support you every step of the way.",
    "You make every moment we spend together special and memorable. I feel so lucky to be your friend.",
    "I trust you completely, and I know I can always count on you. You are always there when I need you.",
    "Nice butt.",
  ];
  return PHRASES[Math.floor(Math.random() * PHRASES.length)];
};

export default function RandomPhrase(): JSX.Element {
  useEffect(() => {
    // only refresh phrase on component mount to avoid "flickering"
    // durring successive rerenders
    displayPhrase = getRandomPhrase();
  }, []);

  return <div>{displayPhrase}</div>;
}
